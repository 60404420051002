import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, getSettings } from '../lib/firebase';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useForm } from '@mantine/form';
import { Button, Stack, Textarea } from '@mantine/core';
import { Settings } from '../lib/firebase.types';

export function SettingsPage() {
    const [user, loading, error] = useAuthState(auth);
    const [settings, setSettings] = useState<Settings>();
    const navigate = useNavigate();

    if (!user) {
        navigate('/login');
    }

    useEffect(() => {
        async function fetchSettings() {
            const settings = await getSettings();
            console.log(settings);
            setSettings(settings);
            form.setFieldValue('rentalRequestReceiverMails', settings?.rentalRequestReceiverMails.join(", ") )
        }
        fetchSettings();
    }, []);

    // handleSettingsSubmit = (values) => {
    //     console.log(values);
    // };

    const form = useForm({

        // validate: {
        //     email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
        // }
    });

    return (
        <div className="flex flex-col">
            <div className="bg-white shadow-md rounded md:px-8 px-4 pt-6 pb-8 md:m-4 m-1">
                <h2 className="text-2xl font-bold">Settings</h2>
                <form onSubmit={form.onSubmit((values) => console.log(values))}>
                    <Stack>
                        <Textarea
                            id="rentalRequestReceiverMails"
                            placeholder="beispiel@web.de, hallo@welt.de"
                            label="Empfänger*innen der Mietanfragen"
                            description="E-Mail Adressen mit Kommas treffen"
                            radius="md"
                            withAsterisk
                            // value={form.}
                        />
                        <Button type="submit"  variant='default' radius="xl">Speichern</Button>
                    </Stack>
                </form>
                {/* {JSON.stringify(settings)} */}
            </div>
        </div>
    );
}
